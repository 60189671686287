{
  "name": "ddesmobilize",
  "version": "0.1.4",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=production --host 0.0.0.0 --port 4200 --disable-host-check",
    "build": "ng build",
    "test": "ng test",
    "watch": "ng build --watch --configuration production",
    "ci:serve": "ng build --configuration=production && angular-http-server --path dist/ddesmobilize/browser -p 4200 --silent",
    "cypress:run": "cypress run --reporter teamcity",
    "cypress:ci": "start-server-and-test ci:serve http://localhost:4200 cypress:run"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.11",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@ngxs-labs/dispatch-decorator": "^5.0.0",
    "@ngxs/store": "^3.8.2",
    "@types/image-to-base64": "^2.1.2",
    "@types/uuid": "^9.0.8",
    "animate.css": "^4.1.1",
    "bootstrap": "^5.3.3",
    "class-transformer": "^0.5.1",
    "cpf-cnpj-validator": "^1.0.3",
    "image-to-base64": "^2.2.0",
    "lz-string": "^1.5.0",
    "moment": "^2.30.1",
    "ngx-mask": "^17.0.8",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@faker-js/faker": "^8.4.1",
    "@types/jasmine": "~5.1.0",
    "angular-http-server": "^1.12.0",
    "cypress": "^13.11.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "start-server-and-test": "^2.0.4",
    "typescript": "~5.4.2"
  }
}
